@import url('../../styles/colors.scss');

.main {
  footer {
    display: flex;
    background: radial-gradient(28.09% 28.09% at 50% 0%, #202020 0%, #020202 100%);
    border-top: 1px solid var(--Dark-gray);
    margin-top: 180px;

    .footer {
      display: flex;
      justify-content: space-between;
      padding: 100px 100px;
      width: 100%;

      .logo {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--White);
        font-weight: 400;
        line-height: normal;
        text-decoration: none;
      }

      .right {
        display: flex;
        gap: 64px;

        .column {
          gap: 24px;
          display: flex;
          flex-direction: column;

          .title {
            color: var(--White);
            font-weight: 500;
            line-height: normal;
            margin-bottom: 12px;
          }

          .social {
            display: flex;
            gap: 12px;
          }

          a {
            color: var(--Gray);
            font-weight: 400;
            line-height: 20px;
            font-size: 14px;
            text-decoration: none;
            display: flex;
            gap: 8px;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1310px) {
  .main {
    footer {
      display: flex;
      justify-content: center;

      .footer {
        width: auto;
        gap: 64px;
        padding: 64px 32px;
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main {
    footer {
      justify-content: flex-start;

      .footer {
        width: auto;
        gap: 64px;
        padding: 32px 16px;
        flex-direction: column;

        .right {
          flex-direction: column;
          gap: 32px;

          .column {
            gap: 12px;
          }
        }
      }
    }
  }
}